import { useContext, useEffect, useState, useRef } from 'react';
import Payment from '../../../feature/AccountSubscription/Payments/Payment';
import { IPlan, IPlanInterval, IPackage } from '../../../interfaces/components';
import { useUserSites } from '../../../hooks/useUserSites';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { ISubscription } from '../../../interfaces/subscription';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import NewFirstTimeCheckoutForm from '../../../feature/AccountSubscription/Checkout/NewFirstTimeCheckoutForm';
import TopHeader from '../../../components/global/TopHeader';
import { useIntercom } from 'react-use-intercom';
import { usePayment } from '../Pricing/usePayment';
import { useAuthHeader, useAuthUser, useSignIn } from 'react-auth-kit';
import {
  ORGANIZATION_ID,
  SUBSCRIPTION_ID_KEY,
} from '../../../constants';

interface Package {
  key: number;
  value: number;
  name: string;
  pricing: {
    month: number;
    year: number;
    total: number;
    annual:number;
    limit: number;
    range?: {
      start: number;
      end: number;
    };
    originalMonthly?: number
  };
}


export const plan = [
  { name: 'Starter', value: 1, monthlyPrice: 89, yearlyPrice: 37 },
  { name: 'Grow', value: 2, monthlyPrice: 129, yearlyPrice: 79 },
  { name: 'Scale', value: 3, monthlyPrice: 249, yearlyPrice: 79 },
  { name: 'Scale Plus', value: 4, monthlyPrice: 449, yearlyPrice: 79 },
  { name: 'Scale Plus 1', value: 5, monthlyPrice: 750, yearlyPrice: 79 },
  { name: 'Scale Plus 2', value: 6, monthlyPrice: 1250, yearlyPrice: 79 },
  { name: 'Scale Plus 3', value: 7, monthlyPrice: 3000, yearlyPrice: 79 },
  { name: 'Freemium', value: 9, monthlyPrice: 0, yearlyPrice: 0 } 
];

export const packages = [
  // { "key": 0, value: 0, "name": "Pro", "pricing": { "month": 47, "year": 37, "limit": 50000 } },
  // { "key": 2, value: 2, "name": "Grow", "pricing": { "month": 129, "year": 129, "limit": 250000 } },
  // { "key": 3, value: 3, "name": "Pro", "pricing": { "month": 249, "year": 249, "limit": 500000 } },
  // { "key": 4, value: 4, "name": "Pro", "pricing": { "month": 449, "year": 5388, "limit": 1000000 } },
  // { "key": 5, value: 5, "name": "Pro", "pricing": { "month": 750, "year": 9000, "limit": 2000000 } },
  // { "key": 6, value: 6, "name": "Pro", "pricing": { "month": 1250, "year": 15000, "limit": 5000000 } },
  // { "key": 7, value: 7, "name": "Pro", "pricing": { "month": 3000, "year": 36000, "limit": 10000000 } },
  // { "key": 8, value: 8, "name": "Pro", "pricing": { "month": 1500, "year": 18000, "limit": 100000000 } },
  // { "key": 1, value: 1, "name": "Pro", "pricing": { "month": 89, "year": 69, "limit": 50000 } },
  // { "key": 9, value: 9, "name": "Freemium", "pricing": { "month": 0, "year": 0,  "annual": 0, "limit": 20000, "range": { "start": 0, "end": 1000000 } } },
  { "key": 1, value: 1, "name": "Pro", "pricing": { "month": 89, "year": 649, "total": 69, "annual": 649, "limit": 999999, "range": { "start": 100000, "end": 999999 } } },
  { "key": 10, value: 10, "name": "Pro", "pricing": { "month": 119, "year": 999, "total": 99, "annual": 999, "limit": 2500000, "range": { "start": 1000000, "end": 2500000 } } },
  { "key": 11, value: 11, "name": "Pro", "pricing": { "month": 159, "year": 1299, "total": 129, "annual": 1299, "limit": 5000000, "range": { "start": 2500000, "end": 5000000 } } },
  { "key": 12, value: 12, "name": "Pro", "pricing": { "month": 219, "year": 1699, "total": 169, "annual": 1699, "limit": 7500000, "range": { "start": 5000000, "end": 7500000 } } },
  { "key": 13, value: 13, "name": "Pro", "pricing": { "month": 299, "year": 2399, "total": 239, "annual": 2399, "limit": 10000000, "range": { "start": 7500000, "end": 10000000 } } },
  { "key": 14, value: 14, "name": "Pro", "pricing": { "month": 449, "year": 3599, "total": 359, "annual": 3599, "limit": 15000000, "range": { "start": 10000000, "end": 15000000 } } },
  { "key": 15, value: 15, "name": "Pro", "pricing": { "month": 699, "year": 5499, "total": 549, "annual": 5499, "limit": 25000000, "range": { "start": 15000000, "end": 25000000 } } },
  { "key": 16, value: 16, "name": "Pro", "pricing": { "month": 999, "year": 7999, "total": 799, "annual": 7999, "limit": 30000000, "range": { "start": 25000000, "end": 30000000 } } },
  { "key": 17, value: 17, "name": "Pro", "pricing": { "month": 1399, "year": 11199, "total": 1119, "annual": 11199, "limit": 40000000, "range": { "start": 30000000, "end": 40000000 } } },
  { "key": 18, value: 18, "name": "Pro", "pricing": { "month": 1749, "year": 13999, "total": 1399, "annual": 13999, "limit": 50000000, "range": { "start": 40000000, "end": 50000000 } } },
  { "key": 19, value: 19, "name": "Pro", "pricing": { "month": 1999, "year": 15999, "total": 1599, "annual": 15999, "limit": 60000000, "range": { "start": 50000000, "end": 60000000 } } },
  { "key": 20, value: 20, "name": "Pro", "pricing": { "month": 2499, "year": 19999, "total": 1999, "annual": 19999, "limit": 75000000, "range": { "start": 60000000, "end": 75000000 } } },
  { "key": 21, value: 21, "name": "Pro", "pricing": { "month": 2999, "year": 23999, "total": 2399,"annual": 23999, "limit": 100000000, "range": { "start": 75000000, "end": 100000000 } } }
];


export const intervals = [
  { name: 'Monthly', value: 'month' },
  { name: 'Yearly', value: 'year' }
];

function getAdjustedPlan(clientSelectedPlan: string | null, packages: Package[]): Package {
  const period = localStorage.getItem('period');
  const selectedPackage = packages.find(pkg => pkg.value.toString() === clientSelectedPlan) || packages[0];
  
  // Create a new object to avoid mutating the original package
  const adjustedPackage: Package = { 
    ...selectedPackage,
    pricing: { ...selectedPackage.pricing }
  };
  
  /* if (period === '12 months' || period === 'year') {
    adjustedPackage.pricing.originalMonthly = adjustedPackage.pricing.month;
    adjustedPackage.pricing.month = Math.round(adjustedPackage.pricing.year / 12);
  } */
  
  return adjustedPackage;
}

export default function Plans() {
  const [loading, setLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Package | null>(null);
  const [clientSelectedPlan, setClientSelectedPlan] = useState<string | null>(null);
  const [intervalSelected, setIntervalSelected] = useState<IPlanInterval>(intervals[0]);
  const [userSubscription, setUserSubscription] = useState<ISubscription | null>(null);
  const { shutdown } = useIntercom();
  // const { abandonCart } = usePayment();

  const [premiumSession, setPremiumSession] = useState<number>(0);

  const { loadUserProfile } = useUserSites();

  // const { profile } = useContext(OrganizationContext);

  const openPaymentModal = () => setModalOpen(true);
  const closePaymentModal = () => setModalOpen(false);
  const { confirmPayment, makePayment, abandonCart, errorMessage } =  usePayment();
  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const signIn = useSignIn();
  const { fetchOrganizationData, profile } = useContext(OrganizationContext);
  const navigate = useNavigate();
  const [tokenType, token] = authHeader().split(' ');
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      shutdown();
      if (localStorage && localStorage.getItem('plan')) {
        const storedPlan = localStorage.getItem('plan');
        setClientSelectedPlan(storedPlan);
        if (storedPlan === '9') {
          makeFreemiumPayment();
          console.log('testing freemium');
        } else {
          loadUserProfile({}).then((res) => {
            setUserSubscription(res.subscription_details);
            setLoading(false);
          });
        }
      } else {
        console.log('log 3');
        loadUserProfile({}).then((res) => {
          setUserSubscription(res.subscription_details);
          setLoading(false);
        });
      }
      effectRan.current = true;
    }
  }, []);

  const makeFreemiumPayment = async () => {
    try {
      const values = {
        name: auth() && auth()?.full_name,
        email: auth() && auth()?.email,
        plan: 9, 
        interval: intervalSelected.value,
        coupon: ''
      };

      const createCustomerResponse = await makePayment({
        name: values.name,
        email: values.email,
        plan: values.plan,
        interval: values.interval,
        coupon: values.coupon
      });

      if (createCustomerResponse && createCustomerResponse.result === 'success') {
        const { subscriptionId } = createCustomerResponse.additional;
        
          // Save subscription ID to local storage to be used on the setup page
          localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);
          localStorage.removeItem('plan');

          // Save organization ID
          localStorage.setItem(
            ORGANIZATION_ID,
            profile && (profile.organizations[0].account_id as string)
          );

          signIn({
            token,
            tokenType: 'Bearer',
            expiresIn: 720,
            authState: {
              ...auth(),
              newUser: false
            }
          });

          setTimeout(() => {
            fetchOrganizationData();
            navigate(routes.SETUP);
          }, 1000);
      }
    } catch (error) {
      console.error('Error processing freemium payment:', error);
      // Handle error appropriately
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const queryPlan = localStorage && localStorage.getItem('plan');
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault();
      await abandonCart({
        plan: queryPlan
          ? parseInt(queryPlan)
          : !((localStorage && localStorage.getItem('plan_opt')) || queryPlan)
          ? 1
          : parseInt(localStorage && (localStorage.getItem('plan_opt') as string), 1)
      });
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (loading)
    return (
      <div className={'min-h-[100vh] bg-gradient-to-t from-emerald-100 via-white to-white'}>
              <div className={'h-[85vh] flex flex-col justify-center items-center'}>
                <LoadingSpinner color={'text-[#008160]'} />
                <h1 className='mt-3'>Processing Plan... Please wait</h1>
              </div>
            </div>
    );

    // Checking for freemium plan
    if (clientSelectedPlan === '9') {
      return (
        <div className={'min-h-[100vh] bg-gradient-to-t from-emerald-100 via-white to-white'}>
              <div className={'h-[85vh] flex flex-col justify-center items-center'}>
                <LoadingSpinner color={'text-[#008160]'} />
                <h1 className='mt-3'>Processing Plan... Please wait</h1>
              </div>
            </div>
      );
    }

  return (
    <div className={'min-h-[100vh] bg-gradient-to-t from-emerald-100 via-white to-white'}>
      {modalOpen && selected ? (
        <Payment
          plan={selected}
          subscription={userSubscription as ISubscription}
          interval={intervalSelected}
          closeModal={closePaymentModal}
        />
      ) : null}
      <TopHeader />
      <main>
        <section className={'p-5 lg:p-10 flex flex-col items-center'}>
          {profile.subscription_details?.card.length === 0 && profile.organizations.length > 1 && (
            <div className={'flex justify-end mb-2 w-full'}>
              <Link
                to={routes.DASHBOARD}
                className={
                  'rounded-full px-3 py-1 bg-white hover:bg-emerald-50 border border-transparent hover:border-[#008160]-lg text-[#008160] flex gap-2 items-center'
                }>
                <span className={'text-sm'}>Skip to dashboard</span>
                <span className={'pt-1'}>
                  <i className="fi fi-rr-arrow-small-right text-xl"></i>
                </span>
              </Link>
            </div>
          )}
          <div>
            {clientSelectedPlan &&
            (clientSelectedPlan == '0' ||
              clientSelectedPlan == '1' ||
              clientSelectedPlan == '2' ||
              clientSelectedPlan == '3' ||
              clientSelectedPlan == '4' ||
              clientSelectedPlan == '5' ||
              clientSelectedPlan == '6' ||
              clientSelectedPlan == '7' ||
              clientSelectedPlan == '8' ||
              clientSelectedPlan == '10' ||
              clientSelectedPlan == '11' ||
              clientSelectedPlan == '12' ||
              clientSelectedPlan == '12' ||
              clientSelectedPlan == '13' ||
              clientSelectedPlan == '14' ||
              clientSelectedPlan == '15' ||
              clientSelectedPlan == '16' ||
              clientSelectedPlan == '17' ||
              clientSelectedPlan == '18' ||
              clientSelectedPlan == '19' ||
              clientSelectedPlan == '20' ||
              clientSelectedPlan == '21' ||
              clientSelectedPlan == '22') ? (
              <div className={'flex flex-col justify-center items-center'}>
                <div className={'w-full flex flex-col items-center justify-center'}>
                  {/* <NewFirstTimeCheckoutForm
                    plan={packages[clientSelectedPlan]}
                    interval={intervalSelected}
                  /> */}
                  <NewFirstTimeCheckoutForm
                  // plan={packages.find(pkg => pkg.value.toString() === clientSelectedPlan) || packages[0]}
                  plan={getAdjustedPlan(clientSelectedPlan, packages)}
                  interval={intervalSelected}
                />
               
                </div>
              </div>
            ) : !localStorage.getItem('plan') ? (
              <div className={'min-h-[100vh] bg-gradient-to-t from-emerald-100 via-white to-white'}>
              <div className={'h-[85vh] flex flex-col justify-center items-center'}>
                <LoadingSpinner color={'text-[#008160]'} />
                <h1 className='mt-3'>Processing Plan... Please wait</h1>
              </div>
            </div>
            
            ) : null}
          </div>
        </section>
      </main>
    </div>
  );
}


